import React from 'react';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import Routes from './Routes';
import Header from './components/Header';
import { useGeneralContext } from './context/GeneralContext';

const childProps = {};

function App() {
  // const history = useHistory();
  const { theme } = useGeneralContext();

  console.log(process.env);
  const t = process.env.REACT_APP_HELLO;
  console.log(t);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          body: { backgroundColor: theme.palette.body.backgroundColor },
        }}
      />
      <Header />
      {/* <SiteWrapper /> */}
      <Routes childProps={childProps} />
    </ThemeProvider>
  );
}

export default withRouter(App);
